import * as Sentry from '@sentry/react'
import { environment, inDevelopment, inE2E } from './environment'
import { WrappedFirestoreError } from '@breakoutlearning/firebase-repository/types'
import { ZodError } from 'zod'
import { inFirefox } from './browser'

function printError(e: Error) {
  let shouldPrint = true
  // A lot of errors are expected to appear in tests and make the output very noisy
  // should you need to inspect errors, you can pass PRINT_FIRESTORE
  if (process.env.NODE_ENV === 'test') shouldPrint = false
  if (process.env.PRINT_FIRESTORE === 'test') shouldPrint = true

  if (shouldPrint) console.error(e.name, e.message, e.stack)
}

function printZodError(e: ZodError) {
  console.error(e.errors)
}

const env = environment()
// We allow sentry in dev so that we can conditionally print firestore errors
const inDev = inDevelopment()
if (!inE2E()) {
  Sentry.init({
    dsn: 'https://0304b5a09de4f022f6c2a80b97b64c34@o4506953915564032.ingest.us.sentry.io/4507176853504000',
    environment: env,
    // Disable all integrations in development
    integrations: inDev
      ? []
      : [
          Sentry.browserTracingIntegration({
            // disabling this in FF as we're getting weird errors in firefox
            instrumentNavigation: inFirefox ? false : true,
          }),
          Sentry.replayIntegration(),
        ],
    // Performance Monitoring
    tracesSampleRate: inDev ? 0 : 0.2, //  Capture 20% of the transactions - disable in dev
    // Session Replay
    replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend(event, hint) {
      const exception = hint?.originalException
      const isWrappedFirestoreError = exception instanceof WrappedFirestoreError
      // do not send events for handled errors
      if (isWrappedFirestoreError && exception.isHandled) return null
      if (isWrappedFirestoreError) printError(exception)
      if (exception instanceof ZodError) printZodError(exception)
      // do not send events in development
      if (inDev) return null
      return event
    },
  })
}
